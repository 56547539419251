.CommentThread {
  margin: 8px;
  font-family: sans-serif;
  border: 1px solid #BFCCD6; /* TODO: Colors.GRAY5 */
  border-radius: 3px;
}

.CommentThreadHeader {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background-color: #EBF1F5;
  cursor: pointer;
}

.CommentThreadHeaderText {
  margin-left: 4px;
}

.CommentThreadHeader:hover {
  background-color: #F5F8FA;
}

.CommentItem {
  padding: 8px;
  border-top: 1px solid #BFCCD6; /* TODO: Colors.GRAY5 */
  font-family: sans-serif;
}
.CommentItem:first-child {
  border-top: none;
}

.CommentItem .Actions {
  visibility: hidden;
}
.CommentItem:hover .Actions {
  visibility: visible;
}

.CommentMeta {
  padding-bottom: 8px;
}

.CommentUser {
  font-weight: bold;
  margin-right: 8px;
}

.CommentBody {
  font-size: 13px;
}
.CommentBody p:last-child {
  margin-bottom: 0;
}

.Actions {
  float: right;
  margin-right: -4px;
  margin-top: -4px;
}

.EditingActions {
  margin-top: 8px;
}
.EditingActions button {
  margin-right: 8px;
}

.CommentThreadFooter {
  border-top: 1px solid #BFCCD6; /* TODO: Colors.GRAY5 */
  background-color: #EBF1F5;
  color: #5C7080; /* TODO: Colors.GRAY1 */
  overflow: hidden;
}

.CommentThreadFooterClosed {
  composes: CommentThreadFooter;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
}

.CommentThreadFooterClosed:hover {
  background-color: #F5F8FA;
  color: #0E5A8A; /* TODO: Colors.BLUE1 */
}

.ReplyText {
  margin-left: 4px;
}
