.Container {
  display: flex;
  flex: 1;
  overflow: auto;
}

.ResizeHandle {
  cursor: ew-resize;
  width: 6px;
}

.Panel {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}

.SidePanel {
  composes: Panel;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 6px 6px;
}

.MainPanel {
  composes: Panel;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 6px 6px 0;
}